import FiveCard from "./layouts/FiveCard.js";
import Mirror from "./layouts/Mirror.js";
import OneCard from "./layouts/OneCard.js";
import ThreeCard from "./layouts/ThreeCard.js";
import Tree from "./layouts/Tree.js";
import TwoCards from "./layouts/TwoCard.js";
import Interpretation from "./Interpretation.js";
import { layouts } from "./data";

function Layout({ layoutName }) {
  if (!layoutName) return null;

  const baseLayouts = {
    OneCard,
    TwoCards,
    ThreeCard,
    FiveCard,
    Tree,
    Mirror,
  };

  const layoutData = layouts[layoutName];
  const baseLayoutName = layoutData.baseLayout;
  const Layout = baseLayouts[baseLayoutName];

  return (
    <>
      <div className="row m-5">
        <span>
          The <b>{layoutData.title}</b> spread {layoutData.description}
        </span>
      </div>
      <div className="row">
        <Layout layoutData={layoutData} />
      </div>
      <Interpretation cardPositions={layoutData.cardPositions} />
    </>
  );
}

export default Layout;
