/* eslint-disable react/jsx-no-target-blank */
import { createContext, useState } from "react";

import Layout from "./Layout.js";
import { drawCardPool } from "./CardPicker.js";
import layouts from "./data/layouts.js";

export const CardDrawContext = createContext();

function App() {
  const [layoutName, setLayoutName] = useState("");
  const [selection, setSelection] = useState("");
  const [cardPool, setCardPool] = useState([]);
  const [isInterpretationVisible, setIsInterpretationVisible] = useState(false);
  const [isIntroVisible, setIsIntroVisible] = useState(true);

  const tab = "\u00A0\u00A0";

  const handleDrawButton = () => {
    setIsInterpretationVisible(false);
    setLayoutName(selection);
    setCardPool(drawCardPool());
    setIsIntroVisible(false);
  };

  const handleChange = (event) => {
    setSelection(event.target.value);
  };

  return (
    <div className="container mb-3">
      <h1 className="text-center mt-5">The Mayan Oracle</h1>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="input-group mt-5 mb-5">
            <select
              className="form-select"
              value={selection}
              onChange={handleChange}
            >
              <option disabled value="">
                Pick a spread...
              </option>

              <option disabled>One card:</option>
              <option value="oneCardConsultation">{tab + layouts.oneCardConsultation.title}</option>
              <option value="yourMayanTotem">{tab + layouts.yourMayanTotem.title}</option>

              <option disabled>Two cards:</option>
              <option value="swordAndChalice">{tab + layouts.swordAndChalice.title}</option>
              <option value="tzolkinMapping">{tab + layouts.tzolkinMapping.title}</option>

              <option disabled>Three cards:</option>
              <option value="illusionIntegrationGift">{tab + layouts.illusionIntegrationGift.title}</option>
              <option value="mindHeartBody">{tab + layouts.mindHeartBody.title}</option>
              <option value="pastPresentFuture">{tab + layouts.pastPresentFuture.title}</option>
              {/* <option value="starLineage">{tab + layouts.starLineage.title}</option> */}
              {/* <option value="treeOfChoice">{tab + layouts.treeOfChoice.title}</option> */}
              <option value="pathOfEcstasy">{tab + layouts.pathOfEcstasy.title}</option>
              {/* <option value="hallOfMirrors">{tab + layouts.hallOfMirrors.title}</option> */}

              <option disabled>Five cards:</option>
              <option value="fourDirections">{tab + layouts.fourDirections.title}</option>
              <option value="becomingTheMindOfLight">{tab + layouts.becomingTheMindOfLight.title}</option>
            </select>
            <button
              className={
                "btn btn-secondary" + (selection === "" ? " disabled" : "")
              }
              type="button"
              onClick={handleDrawButton}
            >
              Draw Cards
            </button>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
      <CardDrawContext.Provider
        value={{
          isInterpretationVisible,
          setIsInterpretationVisible,
          cardPool,
        }}
      >
        <Layout layoutName={layoutName} />
      </CardDrawContext.Provider>
      {isIntroVisible && (
        <div className="m-5">
          <span>
            Welcome to the Mayan Oracle, a spiritual tool inspired by Mayan
            wisdom. This oracle offers deep insights through ancient symbols, 
            guiding you in personal growth and inner exploration. It's not just 
            about foretelling the future but about connecting with your inner 
            self and the cosmic energies. Embrace this journey to discover 
            hidden parts of yourself and unlock the wisdom they hold.
          </span>
        </div>
      )}
      <div className="alert alert-dark mt-5">
        <span>
          Delve deeper into the Mayan Oracle with{" "}
          <a
            target="_blank"
            href="https://www.amazon.com/Mayan-Oracle-Galactic-Language-Light/dp/1591431239/?&_encoding=UTF8&tag=mayanoracle01-20&linkCode=ur2&linkId=cbbd379fe43ce1831f816d2401d68922&camp=1789&creative=9325"
          >
            The Mayan Oracle: Return Path to the Stars
          </a>{" "}
          by Ariel Spilsbury and Michael Bryner. This essential guide provides
          further details on the cards and additional spreads. Please note that
          our website is not affiliated with the authors and does not use any
          original images or passages from the book. By purchasing through our
          Amazon affiliate link, you support the authors and help us cover our
          server costs with no extra charge to you.
        </span>
      </div>
    </div>
  );
}

export default App;
