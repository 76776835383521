import React, { useCallback, useContext, useEffect } from "react";

import CardInfo from "./CardInfo.js";
import { CardDrawContext } from "./App";

function Interpretation({ cardPositions }) {
  const { isInterpretationVisible, setIsInterpretationVisible, cardPool } =
    useContext(CardDrawContext);
  const toggleInterpretation = useCallback(() => {
    setIsInterpretationVisible(!isInterpretationVisible);
  }, [isInterpretationVisible, setIsInterpretationVisible]);

  useEffect(() => {
    setIsInterpretationVisible(isInterpretationVisible);
  }, [isInterpretationVisible, setIsInterpretationVisible]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-6 mx-auto">
          {!isInterpretationVisible && (
            <button
              type="button"
              className="btn btn-secondary my-5  w-100"
              onClick={toggleInterpretation}
            >
              Show Interpretation
            </button>
          )}
        </div>
      </div>
      {isInterpretationVisible && (
        <div className="row m-5">
          {cardPositions
            .sort((a, b) => a.index - b.index)
            .map((cardPositionData, key) => (
              <CardInfo
                cardNumber={
                  cardPool[cardPositionData.type][cardPositionData.index]
                }
                cardPositionData={cardPositionData}
                key={key}
              />
            ))}
        </div>
      )}
    </React.Fragment>
  );
}

export default Interpretation;
