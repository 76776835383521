const cards = {
  1: {
    name: "IMIX",
    reading:
      "Origin of existence, sacred sustenance and aid, the initial Mother, unbroken fundamental waters, elemental faith and nurturing, openness",
  },
  2: {
    name: "IK",
    reading:
      "Essence, life force, breeze, motivation, existence, authenticity, joint architect of existence, straightforwardness, invisible influences, amalgamation of opposites.",
  },
  3: {
    name: "AKBAL",
    reading:
      "Chasm, enigma, haven, tranquility, introspective space, enveloping obscurity, voyage of self-discovery, calmness, realm of dreams, dream landscape framework, journey through dreams.",
  },
  4: {
    name: "KAN",
    reading:
      "Kernel, initiating actualization, self-initiated growth, genesis, fruitful soil, incubation, unfolding, emerging potential.",
  },
  5: {
    name: "CHICCHAN",
    reading:
      "Energy, fervor, perception, physical intelligence, drive, yearning, intuition, inventiveness, snake-like force, spiritual awakening energy, unification, cleansing, closeness.",
  },
  6: {
    name: "CIMI",
    reading:
      "Yielding, letting go, demise, transformation, absolution, modesty, disclosure.",
  },
  7: {
    name: "MANIK",
    reading:
      "Fulfillment, portal, entrance, allure, recognition, rhythm, symbolic gesture, prophecy, pretending, sacred instruments, spiritual leader.",
  },
  8: {
    name: "LAMAT",
    reading:
      "Balance, cosmic seed, advantageous unions, pathfinder, musical interval, amplified affection, unobstructed viewpoint.",
  },
  9: {
    name: "MULUC",
    reading:
      "Indicators, omens, heightened alertness, consciousness, comprehension, self-recognition, heavenly direction, divine essence, universal dialogue, lighthouse.",
  },
  10: {
    name: "OC",
    reading:
      "Advancement, fresh starts, connections, destined allies, emotional challenges, reinterpreting history, spiritual fortitude, protectors and mentors.",
  },
  11: {
    name: "CHUEN",
    reading:
      "Purity, impulsiveness, youthful spirit, wit, creativity, simian, mischief-maker, disturbance, clarity.",
  },
  12: {
    name: "EB",
    reading: "Plenty, yield, cup, void, receptive container, acceleration.",
  },
  13: {
    name: "BEN",
    reading:
      "Adaptable benchmarks, temporal/spatial voyager, celestial wanderer, heavenly courier, supports of the divine, bravery, novel paths, enigmatic voyage.",
  },
  14: {
    name: "IX",
    reading:
      "Honesty, inner wisdom, conformity with sacred intent, sorcerer, spiritual healer, panther, nocturnal visionary, holy person, light carrier, sorcery.",
  },
  15: {
    name: "MEN",
    reading:
      "Optimism, dedication, empathetic aid, worldwide awareness, aspirations and insights, collective intellect, self-confidence.",
  },
  16: {
    name: "CIB",
    reading:
      "Elegance, faith, internal guidance, universal channel, acceptance, mystical conveyance, celestial dialogue, cosmic awareness, radiant column, guide's rod.",
  },
  17: {
    name: "CABAN",
    reading:
      "Equilibrium, collaboration, synchronicity, terrestrial energy, cosmic coordination, guardian of the Earth, planetary harmonic vibration, sign tracing, quartz therapy, protector.",
  },
  18: {
    name: "ETZNAB",
    reading:
      "Eternity, discernment, lucidity, chamber of reflections, spiritual combat, blade of veracity, confronting inner darkness, amalgamation of contradictions.",
  },
  19: {
    name: "CAUAC",
    reading:
      "Cleansing, alteration, unification, luminous form, stimulation for elevation, storm entity, path of electricity, fiery beginning, bliss of liberation.",
  },
  20: {
    name: "AHAU",
    reading:
      "Fusion, completeness, rise, absolute affection, solar expertise, consciousness of spiritual awakening, dialect of radiance, extreme joy, boundless happiness, crown.",
  },
  21: {
    name: "ONE (Unity)",
    reading:
      "Absolute affection, unified heart, inseparable nature, fundamental frequency, fresh starts, vessel of the boundless, The Everything.",
  },
  22: {
    name: "TWO (Polarity)",
    reading:
      "Fabric of existence, holy duo, collaboration, partnership, male and female dichotomy, binary nature.",
  },
  23: {
    name: "THREE (Rhythm)",
    reading:
      "Motion, transformation, progression, stream, inventiveness, amalgamation, holy triad.",
  },
  24: {
    name: "FOUR (Measure)",
    reading:
      "Structure, clarification, discernment, rhythmic patterns, purposeful arrangement.",
  },
  25: {
    name: "FIVE (Center)",
    reading:
      "Essential objective, primary focus, base, locus of equilibrium, straightforwardness, embracing human nature.",
  },
  26: {
    name: "SIX (Organic Balance)",
    reading:
      "Openness, capacity to react, active balance, foundations in multiple realms.",
  },
  27: {
    name: "SEVEN (Mystical Power)",
    reading:
      "Spiritual harmony, personal embrace, expert in the holy, immediate linkage with the Origin.",
  },
  28: {
    name: "EIGHT (Harmonic Resonance)",
    reading: "Strengthening, cosmic network, journey of the unified heart.",
  },
  29: {
    name: "NINE (Greater Cycles)",
    reading:
      "Achievement, growth, expertise, extensive temporal phases, realization, magnificent plan.",
  },
  30: {
    name: "TEN (Manifestation)",
    reading:
      "Purpose, drive, authentic self, basis of Core Being, harmonized creation.",
  },
  31: {
    name: "ELEVEN (Dissonance)",
    reading:
      "Alteration, breakdown, release, removal, from intricacy to straightforwardness.",
  },
  32: {
    name: "TWELVE (Complex Stability)",
    reading:
      "Firmness in growth, broader assembly, merging of opposites, structure of interconnection.",
  },
  33: {
    name: "THIRTEEN (Universal Movement)",
    reading: "Unpredictable element, hidden powers, unforeseen transformation.",
  },
  34: {
    name: "LANGUAGE OF LIGHT",
    reading:
      "Portal, immediate entry, communications, emotions, readiness for spiritual elevation, surpassing awareness.",
  },
  35: {
    name: "SHADOW",
    reading:
      "Response, unresolved matters, unrevealed traits, concealed capabilities, path to completeness.",
  },
  36: {
    name: "RESOLUTION OF DUALITY",
    reading:
      "Recognition of dichotomy, collective awareness, individual settlement, merging of dual aspects.",
  },
  37: {
    name: "ADVENTURER'S QUEST",
    reading:
      "Legendary summons, voyage, pursuit, mentors, companions, openness, commencement, strengthening.",
  },
  38: {
    name: "REALM SHIFT",
    reading:
      "Significant advancement, broadened existence, network of potential, gathering sites, the undiscovered.",
  },
  39: {
    name: "HOLOGRAM",
    reading:
      "Mirrored representation of unity, enhanced reality, widened perspective, development towards completeness.",
  },
  40: {
    name: "TRANSFORMER",
    reading:
      "Planetary actuality, shared dream realm, personal recovery, converter of energy.",
  },
  41: {
    name: "NEW MYTH",
    reading:
      "Life narrative, overarching myth, life's mission, extraordinary event on our planet, fresh harmonious phase, journey of purity, current instant.",
  },
  42: {
    name: "DREAMER AND DREAMED",
    reading:
      "Fate, individual aspiration, meaningful coincidences, intricate tapestry of significance, the grander vision.",
  },
  43: {
    name: "TRANSPARENCY",
    reading:
      "Self-recognition, participant, experimentation with alternate personas, deconstruction of one's identity.",
  },
  44: {
    name: "PORTAL OF TRANSCENDENCE",
    reading:
      "Joy, celestial portal, euphoric connection, hidden entrance, visible and invisible, vast emptiness, mysterious and familiar, revealer and revealed.",
  },
};

export default cards;
