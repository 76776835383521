import { cards } from "./data";

function CardInfo(props) {
  const name = cards[props.cardNumber].name;
  const reading = cards[props.cardNumber].reading;
  const cardPositionData = props.cardPositionData;
  const description = cardPositionData.description;
  const meaning = cardPositionData.meaning;

  return (
    <div className="mb-2">
      <i>{meaning && <span>The <b>{meaning}</b> card represents </span>}
      {description}:</i>
      <br />
      <span><b>{name}</b> – {reading}</span>
      <br /><br />
    </div>
  );
}

export default CardInfo;
