const layouts = {
  oneCardConsultation: {
    title: "One-Card Consultation",
    baseLayout: "OneCard",
    description:
      "provides focused guidance for daily concerns or personal queries. Contemplate and relate the drawn card's message to your situation, and let your intuition guide you to the most beneficial understanding.",
    cardPositions: [
      {
        description: "The critical factor for the issue at hand",
        type: "mixed",
        meaning: null,
        index: 0,
      },
    ],
  },
  yourMayanTotem: {
    title: "Your Mayan Totem",
    baseLayout: "OneCard",
    description:
      "uncovers your inherent abilities and life lessons by identifying a star-glyph archetype that resonates with you currently.",
    cardPositions: [
      {
        description: "This archetype is your totem, a symbol of where you are now on your journey",
        type: "glyphs",
        meaning: null,
        index: 0,
      },
    ],
  },
  swordAndChalice: {
    title: "Sword and Chalice",
    baseLayout: "TwoCards",
    description:
      "delves into your inner contrasts, encouraging you to contemplate and harmonize these elements within yourself",
    cardPositions: [
      {
        description: "the aspect of your consciousness that is active, analytical, and assertive, often associated with masculine energy",
        type: "glyphs",
        meaning: "Sword",
        index: 0,
      },
      {
        description: "the part of you that is more intuitive, open, and nurturing, typically linked with feminine energy",
        type: "glyphs",
        meaning: "Chalice",
        index: 1,
      },
    ],
  },
  tzolkinMapping: {
    title: "Tzolkin Mapping",
    baseLayout: "TwoCards",
    description:
      "reveals the interplay of unseen forces shaping specific times and energies. Reflect on the card combination to gain insights into your current challenges",
    cardPositions: [
      {
        description: "the primary energy influencing your current situation",
        type: "glyphs",
        meaning: "Star-glyph",
        index: 0,
      },
      {
        description: "a secondary force that combines with the Star Glyph's energy, weaving a complex web of temporal and spatial dynamics",
        type: "numerals",
        meaning: "Resonance",
        index: 1,
      },
    ],
  },
  illusionIntegrationGift: {
    title: "Illusion, Integration, Gift",
    baseLayout: "ThreeCard",
    description:
      "is designed to reveal hidden truths, pathways to resolution, and the benefits that arise from a situation.",
    cardPositions: [
      {
        description: "overlooked or hidden elements in your current scenario",
        type: "mixed",
        meaning: "Illusion",
        index: 0,
      },
      {
        description: "transformative actions or insights that can resolve existing challenges",
        type: "mixed",
        meaning: "Integration",
        index: 1,
      },
      {
        description: "the ultimate learning or benefit derived from the situation",
        type: "mixed",
        meaning: "Gift",
        index: 2,
      },
    ],
  },
  mindHeartBody: {
    title: "Mind, Heart, Body",
    baseLayout: "ThreeCard",
    description:
      "focuses on the holistic understanding of a person's current state, covering mental, emotional, and physical aspects. It reveals how thoughts, feelings, and actions are interconnected and influencing the present situation.",
    cardPositions: [
      {
        description: "your current thoughts, beliefs, and mental focus",
        type: "mixed",
        meaning: "Mind",
        index: 0,
      },
      {
        description: "your emotions and feelings. It speaks to your emotional state and how it shapes your experience",
        type: "mixed",
        meaning: "Heart",
        index: 1,
      },
      {
        description: "your physical presence, actions, and how you manifest your energy in the material world",
        type: "mixed",
        meaning: "Body",
        index: 2,
      },
    ],
  },
  pastPresentFuture: {
    title: "Past, Present, Future",
    baseLayout: "ThreeCard",
    description:
      "offers a temporal perspective on a person's life journey. It examines the influence of past experiences, the nature of current circumstances, and the potential trajectory towards future possibilities.",
    cardPositions: [
      {
        description: "your history, past experiences, and events that have shaped who you are today. It provides context and background to your current situation",
        type: "glyphs",
        meaning: "Past",
        index: 0,
      },
      {
        description: "your current circumstances, challenges, and the state of your life as it stands now. It offers insight into the issues and opportunities you are currently facing",
        type: "glyphs",
        meaning: "Present",
        index: 1,
      },
      {
        description: "potential outcomes and possibilities. It indicates the direction you are heading towards based on your current path, highlighting possible future developments and opportunities",
        type: "glyphs",
        meaning: "Future",
        index: 2,
      },
    ],
  },
  pathOfEcstasy: {
    title: "Path of Ecstasy",
    baseLayout: "ThreeCard",
    description:
      "is tailored to connect you more intimately with your personal journey towards joy.",
    cardPositions: [
      {
        description: "that which would bring you utmost happiness and fulfillment",
        type: "mixed",
        meaning: "Greatest Joy",
        index: 0,
      },
      {
        description: "the barriers hindering your path to joy",
        type: "mixed",
        meaning: "Obstacle",
        index: 1,
      },
      {
        description: "the means or solution to overcome these obstacles and achieve true happiness",
        type: "mixed",
        meaning: "Bridge",
        index: 2,
      },
    ],
  },
  starLineage: {
    title: "Star Lineage",
    baseLayout: "ThreeCard",
    description:
      "aids in uncovering your unique abilities and past life experiences, urging you to weave these talents into your current existence. Reflect on how to shine as a light worker with these qualities.",
    cardPositions: [
      {
        meaning: "Past",
        description: "the traits and skills you've previously developed and exhibited",
        type: "glyphs",
        index: 0,
      },
      {
        meaning: "Present",
        description: "the abilities you're currently focused on cultivating in this lifetime",
        type: "glyphs",
        index: 1,
      },
      {
        meaning: "Shadow",
        description: "the challenges or barriers that, once addressed, can lead to a deeper self-understanding",
        type: "glyphs",
        index: 2,
      },
    ],
  },
  treeOfChoice: {
    title: "Tree of Choice",
    baseLayout: "Tree",
    description:
      "derives from the Mayan concept of seeking truth's root. It's particularly useful in illuminating choices you face in life.",
    cardPositions: [
      {
        meaning: "Branches",
        description: "the underlying, often subconscious challenges or blockages",
        type: "glyphs",
        index: 2,
      },
      {
        meaning: "Trunk",
        description: "your essential self or the unseen spiritual support that forms your foundation",
        type: "glyphs",
        index: 1,
      },
      {
        meaning: "Roots",
        description: "the most profound guidance from your inner wisdom, guiding your personal growth",
        type: "glyphs",
        index: 0,
      },
    ],
  },
  hallOfMirrors: {
    title: "Hall of Mirrors",
    baseLayout: "Mirror",
    description:
      "uncovers hidden or challenging aspects in your life, offering a pathway towards growth, wisdom, and clarity.",
    cardPositions: [
      {
        meaning: "Through the Mirror",
        description: "the transformative experience of gaining complete self-awareness and the changes it can bring",
        type: "glyphs",
        index: 2,
      },
      {
        meaning: "Smoky Mirror",
        description: "the deceptive or hidden aspects of a situation that are challenging. It encourages confronting and understanding these obscured elements",
        type: "glyphs",
        index: 0,
      },
      {
        meaning: "Clear Mirror",
        description: "insights and truths that offer a clearer understanding and guidance",
        type: "glyphs",
        index: 1,
      },
    ],
  },
  fourDirections: {
    title: "Four Directions",
    baseLayout: "FiveCard",
    description:
      "provides varied perspectives on a question or issue. Consider different viewpoints, allowing your intuition to integrate the insights, then listen to your inner voice for revelations.",
    cardPositions: [
      {
        description: "the viewpoint of Wisdom and Purification",
        meaning: "North",
        subMeaning: "Wisdom / Purification",
        type: "mixed",
        index: 4,
      },
      {
        description: "the viewpoint of Death and Transformation",
        meaning: "West",
        subMeaning: "Death / Transformation",
        type: "mixed",
        index: 3,
      },
      {
        description: "the viewpoint of Spirit and Essence Self",
        meaning: "Center",
        subMeaning: "Spirit / Essence Self",
        type: "mixed",
        index: 0,
      },
      {
        description: "the viewpoint of Vision and Illumination",
        meaning: "East",
        subMeaning: "Vision / Illumination",
        type: "mixed",
        index: 1,
      },
      {
        description: "the viewpoint of Innocence, Trust, and Teaching",
        meaning: "South",
        subMeaning: "Innocence / Trust / Teaching",
        type: "mixed",
        index: 2,
      },
    ],
  },
  becomingTheMindOfLight: {
    title: "Becoming the Mind of Light",
    baseLayout: "FiveCard",
    description:
      "unfolds the five mythic gateways of Mayan cosmology, guiding you in understanding where you stand on your spiritual journey and the steps needed to progress towards the Mind of Light.",
    cardPositions: [
      {
        meaning: "North Gate",
        subMeaning: "Self-Embodiment",
        description: "the journey to Self-Embodiment — integrating and living your spiritual truths in everyday life, embodying the wisdom you've gained",
        type: "mixed",
        index: 3,
      },
      {
        meaning: "West Gate",
        subMeaning: "Self-Love",
        description: "the journey to Self-Love — the process of recognizing and valuing your inherent worth and uniqueness",
        type: "mixed",
        index: 0,
      },
      {
        meaning: "Center",
        subMeaning: "Transparency",
        description: "the journey to Transparency — developing a pure, unattached faith in the Divine, aligning oneself with the higher purpose",
        type: "mixed",
        index: 4,
      },
      {
        meaning: "East Gate",
        subMeaning: "Self-Reflection",
        description: "the journey to Self-Reflection — a deep self-examination, where you confront and understand your true nature through introspection",
        type: "mixed",
        index: 2,
      },
      {
        meaning: "South Gate",
        subMeaning: "Self-Empowerment",
        description: "the journey to Self-Empowerment — emphasizing self-reliance and the ability to express your true self confidently, independent of external validation",
        type: "mixed",
        index: 1,
      },
    ],
  },
};

export default layouts;
