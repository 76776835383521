import Card from "../Card.js";

function ThreeCard(props) {
  const cardPositions = props.layoutData.cardPositions;

  return (
    <>
      <div className="d-flex flex-wrap justify-content-around">
        <Card cardPositionData={cardPositions[0]} />
        <Card cardPositionData={cardPositions[1]} />
        <Card cardPositionData={cardPositions[2]} />
      </div>
    </>
  );
}

export default ThreeCard;
