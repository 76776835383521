import Card from "../Card.js";

function OneCard(props) {
  const cardPositions = props.layoutData.cardPositions;

  return (
    <>
      <div className="d-flex flex-wrap justify-content-around">
        <Card cardPositionData={cardPositions[0]} />
      </div>
    </>
  );
}

export default OneCard;
