import { useContext, useEffect, useState } from "react";

import { CardDrawContext } from "./App";
import ReactCardFlip from "react-card-flip";
import back from "./images/back.png";

const cardDelay = process.env.REACT_APP_CARD_DELAY;

function Card(props) {
  const { cardPool } = useContext(CardDrawContext);
  const [isFlipped, setIsFlipped] = useState(false);
  const [hidden, setHidden] = useState("d-none");
  const cardPositionData = props.cardPositionData;

  useEffect(() => {
    const showCard = setTimeout(() => {
      setHidden("d-block");
    }, cardPositionData.index * cardDelay);

    return () => clearTimeout(showCard);
  }, [cardPositionData.index]);

  useEffect(() => {
    setIsFlipped(false);
  }, [cardPool]);

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(true);
  };

  const getCardPath = () => {
    const cardNumber = cardPool[cardPositionData.type][cardPositionData.index];
    return process.env.PUBLIC_URL + "/cards/" + cardNumber + ".png";
  };

  return (
    <div>
      <div style={{ width: 253, height: 353 }}>
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <div className={hidden}>
            <img
              src={back}
              alt="card back"
              width="253"
              height="353"
              onClick={handleClick}
            />
          </div>
          <div className={isFlipped ? "d-block" : "d-none"}>
            <img
              src={getCardPath()}
              alt="card front"
              width="253"
              height="353"
              onClick={handleClick}
              className={hidden}
            />
          </div>
        </ReactCardFlip>
      </div>
      <h5 className="text-center mt-4">{cardPositionData.meaning}</h5>
      <h6 className="text-center mb-5">{cardPositionData.subMeaning}</h6>
    </div>
  );
}

export default Card;
